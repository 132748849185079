//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex";
export default {
  data() {
    return {
      shopDetail: {},
      productList: []
    };
  },
  created() {
    let restaurantId = this.$route.query.restaurantId;
    console.log(restaurantId);
    this.shopDetail = this.shopList.filter(item => {
      return item.restaurantId == restaurantId;
    });
    console.log(this.shopDetail);
    this.getRestaurantList();
  },
  computed: {
    ...mapGetters(["shopList"])
  },
  methods: {
    getRestaurantList() {
      this.productList = [];
      let sectionList = this.shopDetail[0].sectionList || [];
      for (let i = 0; i < sectionList.length; i++) {
        let dishList = sectionList[i].dishList;
        for (let j = 0; j < dishList.length; j++) {
          console.log(dishList[j]);
          //   dishList[j].priceInCent = "¥"+ ((dishList[j].priceInCent)/100).toFixed(2);
          this.productList.push(dishList[j]);
        }
      }
      console.log(this.productList);
    }
  },
  filters: {
    priceInCent: value => {
      return "¥" + (value / 100).toFixed(2);
    }
  }
};
